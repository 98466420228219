import logo from "./logo.svg";
import "./App.css";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import Nav from "./components/Nav/Nav";
import { Outlet } from "react-router";
import { Margin } from "@mui/icons-material";
import Footer from "./components/Footer/Footer";
import { CssBaseline } from "@mui/material";

Amplify.configure(awsExports);

function App() {
  return (
    <>
      <CssBaseline />
      <Nav />
      <main className="main-box">
        <Outlet />
      </main>
      <Footer />
    </>
  );
}

export default App;

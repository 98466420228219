import "./Soon.css";
import Logo from "../Logo/Logo";

const Soon = (props) => {

    return (
        <div className="App">
            <header className="App-header">
                <Logo className="App-logo" svgWidth="75vw" svgHeight="75vh" />
                <p>Our website is under maintenance. We apologize for the inconvenience. If you need assistance, please get in touch with us at (770)758-4950 or sales at brownsbridgepw.com.</p>
            </header>
        </div>
    )
};

export default Soon;
import { redirect, useNavigate } from "react-router";
import {
  AppBar,
  Box,
  Button,
  Container,
  Icon,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import ReceiptIcon from "@mui/icons-material/Receipt";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const pages = [
  {
    name: "Get Quote",
    icon: <ReceiptIcon />,
  },
];

const Nav = () => {
  const navigation = useNavigate();
  const onQuoteButtonClicked = async (event) => {
    navigation("/quote");
  };

  const onScheduleClicked = async (event) => {
    navigation("/schedule");
  };

  const onContactUsClicked = async (event) => {
    navigation("/contact");
  };

  return (
    <AppBar position="fixed">
      <Toolbar sx={{ backgroundColor: "white" }}>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2, color: "#143c6c" }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1, color: "#143c6c" }}
        >
          Browns Bridge Pressure Washing
        </Typography>
        <Button
          color="inherit"
          onClick={onQuoteButtonClicked}
          sx={{ color: "#143c6c" }}
        >
          <ReceiptIcon sx={{ paddingRight: 1 }} />
          Quote
        </Button>

        <Button
          color="inherit"
          onClick={onScheduleClicked}
          sx={{ color: "#143c6c" }}
        >
          <CalendarMonthIcon sx={{ paddingRight: 1 }} />
          Schedule
        </Button>

        <Button
          color="inherit"
          onClick={onContactUsClicked}
          sx={{ color: "#143c6c" }}
        >
          <PhoneIphoneIcon sx={{ paddingRight: 0.5 }} />
          Contact
        </Button>
      </Toolbar>
    </AppBar>
    //</Box>
  );
};

export default Nav;

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "../../App.css";
import App from "../../App";
import ErrorPage from "../ErrorPage/ErrorPage";
import Quote from "../Quote/Quote";
import Schedule from "../Schedule/Schedule";
import Contact from "../Contact/Contact";

const SiteRoutes = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "quote",
        element: <Quote />,
      },
      {
        path: "schedule",
        element: <Schedule />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
    ],
  },
]);
export default SiteRoutes;

import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import { RouterProvider } from "react-router-dom";
import SiteRoutes from "./components/routes/SiteRoutes";
import Soon from "./components/Soon/Soon";
import Logo from "./components/Logo/Logo";
const root = ReactDOM.createRoot(document.getElementById("root"));

//root.render(
//  <React.StrictMode>
//    <RouterProvider router={SiteRoutes} />
//  </React.StrictMode>
//);
root.render(
  <React.StrictMode>
    <Soon />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
